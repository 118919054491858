/* open-sans-300 - latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-300.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Light'), local('OpenSans-Light'),
    url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-300.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-300.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-300.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-300.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-300.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

/* open-sans-regular - latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-regular.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-regular.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

/* open-sans-600 - latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-600.eot'); /* IE9 Compat Modes */
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
    url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-600.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-600.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-600.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-600.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-600.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-700.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-700.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-700.woff')
      format('woff'),
    /* Modern Browsers */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-700.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./assets/fonts/open-sans/open-sans-v17-latin-ext_greek-ext_vietnamese_greek_cyrillic_cyrillic-ext_latin-700.svg#OpenSans')
      format('svg'); /* Legacy iOS */
}

@font-face {
  font-family: 'Philosopher';
  src: url('./assets/fonts/philisopher/Philosopher-Regular-webfont.eot');
  src: url('./assets/fonts/philisopher/Philosopher-Regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/philisopher/Philosopher-Regular-webfont.woff') format('woff'),
  url('./assets/fonts/philisopher/Philosopher-Regular-webfont.ttf') format('truetype'),
  url('./assets/fonts/philisopher/Philosopher-Regular-webfont.svg#philosopherbold_italic') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Philosopher';
  src: url('./assets/fonts/philisopher/Philosopher-Bold-webfont.eot');
  src: url('./assets/fonts/philisopher/Philosopher-Bold-webfont.eot?#iefix') format('embedded-opentype'),
  url('./assets/fonts/philisopher/Philosopher-Bold-webfont.woff') format('woff'),
  url('./assets/fonts/philisopher/Philosopher-Bold-webfont.ttf') format('truetype'),
  url('./assets/fonts/philisopher/Philosopher-Bold-webfont.svg#philosopherbold_italic') format('svg');
  font-weight: bold;
  font-style: normal;
}

html {
  width: 100vw;
  height: 100%;
}

#root {
  width: 100vw;
  height: 100%;
}


body {
  margin: 0;
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  height: 100%;

}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

button {
  font-family: inherit;
  outline: none;
  cursor: pointer;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0;
  padding: 0;
}

.fill-available {
  width: 100vw;
  height: 100%;
}

